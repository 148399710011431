@font-face {

    font-family: 'Nobel-Book';
    
    src: url("../../assets/fonts/Nobel-Book.eot?#iefix") format("embedded-opentype"), 
    
    url("../../assets/fonts/Nobel-Book.woff") format("woff"), 
    
    url("../../assets/fonts/Nobel-Book.ttf") format("truetype"), 
    
    url("../../assets/fonts/Nobel-Book.svg#Nobel-Book") format("svg");
    
           font-weight: normal;
    
           font-style: normal; 
    
    }
    
    
    
    
    
    /*
    @font-face {
    
    font-family: 'Nobel-Book';
    
    src: url("https://content.travelhq.com/cp/2019/lexus/fonts/Nobel-Book.eot?#iefix") format("embedded-opentype"), 
    
    url("https://content.travelhq.com/cp/2019/lexus/fonts/Nobel-Book.woff") format("woff"), 
    
    url("https://content.travelhq.com/cp/2019/lexus/fonts/Nobel-Book.ttf") format("truetype"), 
    
    url("https://content.travelhq.com/cp/2019/lexus/fonts/Nobel-Book.svg#Nobel-Book") format("svg");
    
           font-weight: normal;
    
           font-style: normal; 
    
    }
    */
    