@import '../../../../app/app.variables.scss';

#agenda {
  .agendaTabs {
    border: 1px solid #ddd;
    border-top: 1px solid transparent;
    width: 100%;
    height: auto;
    float: left;
    clear: left;
    margin-bottom: 30px;

    ul {
      &.nav-tabs {
        position: relative;
        width: 100%;
        //display: table;
        height: auto;
        float: left;
        clear: left;
        margin: 0;
        padding: 0;

        // @media (max-width: 420px) {
        //   flex-direction: row;
        // }

        > .active {
          a {
            border-bottom-color: none;

            border: none;
            background-color: transparent;
            font-weight: 200;
            color: #fff;
          }
        }

        > li {
          a {
            border-top: 1px solid #ddd;
            border-radius: 0 !important;
          }
        }

        li {
          clear: none !important;

          &.nav-item, &.nav-item-global {
            background: $brand-white;
            color: $brand-primary !important;
            position: relative;
            display: table-cell;
            text-align: center;
            line-height: 1.4em;
            padding: 0;
            border-right: 1px solid #dadada;
            cursor: pointer;
            transition: all 300ms ease;
            -webkit-transition: all 300ms ease;
            -ms-transition: all 300ms ease;
            -o-transition: all 300ms ease;
            -moz-transition: all 300ms ease;

            @media (max-width: 500px) {
              flex-wrap: wrap;
              border-left: 1px solid #dadada;
            }

            @media (max-width: 845px) {
              // display: block;
              // width: 100%;
              // float: left;
              // clear: left;
              // border: 1px solid #d0d0d0 !important;
              // margin-bottom: 0px;
            }

            a.nav-link {
              padding: 20px 10px;
              text-decoration: none !important;
              color: $brand-secondary !important;
              // border-color: $brand-secondary!important;
              // border-bottom-color: transparent!important;

              &.nav-link {
                @media (max-width: 845px) {
                  border-bottom: 1px solid #ddd;
                }

                span {
                  margin: 0px;
                  padding: 0px;
                  border: none;
                  outline: none;

                  &.day {
                    display: block;
                    text-transform: capitalize;
                    font-size: 22px;
                    margin-bottom: 7px;
                  }

                  &.date {
                    display: block;
                    font-size: 15px;
                  }

                  &.curve {
                    position: absolute;
                    display: block;
                    width: 100%;
                    left: 0px;
                    bottom: 0px;
                    background: none;
                    text-align: center;
                    opacity: 0;

                    @media (max-width: 845px) {
                      display: none;
                    }

                    &:after {
                      content: '';
                      display: inline-block;
                      width: 0px;
                      height: 0px;
                      border: 7px solid transparent;
                      border-top-color: #dadada;
                      // border-top-color: $brand-primary;
                    }
                  }
                }

                &:hover {
                  // background-color: #d2d2d2;
                  span {
                    &.curve {
                      bottom: -16px;
                      // bottom: -19px;
                      opacity: 1;
                      transition: all 300ms ease 200ms;
                      -webkit-transition: all 300ms ease 200ms;
                      -ms-transition: all 300ms ease 200ms;
                      -o-transition: all 300ms ease 200ms;
                      -moz-transition: all 300ms ease 200ms;
                    }
                  }
                }
              }

              &.hover {
                border-color: transparent;
                background: $brand-secondary;
                color: $brand-white;
                border: none !important;
                outline: none !important;
                border-radius: 0;

                span {
                  &.curve {
                    bottom: -16px;
                    // bottom: -19px;
                    opacity: 1;
                    transition: all 300ms ease 200ms;
                    -webkit-transition: all 300ms ease 200ms;
                    -ms-transition: all 300ms ease 200ms;
                    -o-transition: all 300ms ease 200ms;
                    -moz-transition: all 300ms ease 200ms;
                  }
                }
              }

              &.active {
                border-color: transparent;
                background: $brand-secondary-plum-darker;
                color: $brand-white !important;
                border: none !important;
                outline: none !important;
                border-radius: 0;

                a {
                  border-bottom: none !important;
                  color: $brand-white !important;
                }

                span {
                  &.curve {
                    bottom: -16px;
                    // bottom: -19px !important;
                    opacity: 1 !important;
                    transition: all 300ms ease 200ms;
                    -webkit-transition: all 300ms ease 200ms;
                    -ms-transition: all 300ms ease 200ms;
                    -o-transition: all 300ms ease 200ms;
                    -moz-transition: all 300ms ease 200ms;

                    &:after {
                      border-top-color: $brand-secondary !important;
                    }
                  }
                }
              }
            }
          }

          &.nav-item {
            width: 33.33%;
            @media (max-width: 500px) {
                width: 100%; 
                flex-wrap: wrap;
                border-left: 1px solid #dadada;
            }
          }

          &.nav-item-global {
            width: 25%;
            @media (max-width: 500px) {
                width: 50%; 
                flex-wrap: wrap;
                border-left: 1px solid #dadada;
            }
          }
        }
      }
    }

    .tab-content {
      width: 100%;
      height: auto;
      float: left;
      clear: left;
      padding: 20px 20px;
      background: $table-even-rows;
      margin: 0 !important;

      .tab-pane {
        width: 100%;
        height: auto;
        float: left;
        clear: left;
        position: relative;

        h2 {
          color: $brand-secondary;

          @media (max-width: 845px) {
            font-size: 18px;
          }
        }

        .hour-box {
          position: relative;
          padding-left: 200px;
          font-size: 16px;
          color: #7f7f7f;
          padding-bottom: 20px;
          overflow: hidden;

          @media (max-width: 845px) {
            padding-left: 40px;
          }

          &:before {
            content: '';
            position: absolute;
            left: 181px;
            top: 24px;
            width: 2px;
            height: 100%;
            background: #d2d2d2;

            @media (max-width: 845px) {
              left: 11px;
            }
          }

          .hour {
            position: absolute;
            left: 0px;
            top: -4px;
            width: 230px;
            font-size: 14px;
            line-height: 2em;
            color: $brand-primary;
            text-transform: uppercase;

            @media (max-width: 845px) {
              position: relative;
              margin-bottom: 15px;
            }
          }

          .circle {
            position: absolute;
            left: 170px;
            top: 0px;
            width: 24px;
            height: 24px;
            border: 2px solid #d2d2d2;
            border-radius: 100%;

            @media (max-width: 845px) {
              left: 0;
            }
          }

          .toggle-btn {
            h3 {
              margin-top: 0;
              position: relative;
              display: inline-block;
              font-size: 18px;
              color: #333;
              text-transform: uppercase;
              padding: 0;

              @media (max-width: 845px) {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}

// styles used by FIB agenda
#square-container {
  width: 100%;

  .agenda-square {
    width: 33%;
    border: 1px solid #dfdfdf;
    background-color: #faf9f9;
    border-radius: 0.33rem;
    padding: 1rem;
    margin: 0 0.1rem;

    ul {
      // margin: 0 !important;
      list-style-type: disc;
      margin-block-start: 0;
      margin-block-end: 1rem;
      margin-inline-start: 0px;
      margin-inline-end: 0;
      padding-inline-start: 0.5rem;
      border-bottom: 1px dotted #7f7f7f;

      &:last-child {
        border-bottom: none;
      }

      li:last-child {
        padding-bottom: 1rem;
      }

      li > ul {
        list-style-type: square;
      }

      li > ul > li {
        font-size: 0.86rem;
      }
    }

    @media (max-width: $screen-xs-max) {
      width: 100%;
      margin: 0.1rem 0;
    }
  }

  .timeOfDay {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.98;
    background: antiquewhite;
    padding: 0.3rem;
    border-radius: 0.15rem;
  }
  .day {
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 600;
  }
}
