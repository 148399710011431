body {
	
}
.hotelPhoto {
    width: 40%;
    margin-right: 1rem;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2rem;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
  .hotel-pretrip-package {
    width: 100%;
    max-width: 700px;
    padding: 2rem 1rem 6rem 1rem;
    margin: 2rem 0px;
    border-bottom: 1px solid #58595B;
  
    img {
    width: 100%;
    display: block;
    margin: 1rem 0px;
    }
  }
  h2 {
    margin-bottom: 0rem;
  
    @media (max-width: 768px) {
      font-size: 1.4rem;
      margin-bottom: .6rem;
    }
  }

 

