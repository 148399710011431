@import '../../../../app/app.variables.scss';

#faqs {

    h2 {
      margin-top: 2rem;
    }
    
    ngb-accordion {
      .accordion-item {
        margin-bottom: 1.25rem;
        display:block;
        .accordion-header {
            button {
              &.accordion-button {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: nowrap;
                text-decoration: none!important;
                text-align: left;
                color: #fff!important;
                background: $brand-secondary-plum;
                padding: 10px;
                box-sizing: border-box;
                border-radius: 10px;
                margin-bottom:1rem;
                
                .accordion_dt {
                  width: calc(100% - 40px);
                  height: auto;
                  font-size: 1.4rem;
                  margin-bottom: 0!important;
                  font-weight: 300;
                  align-self: center;
    
                }
              }
            }
          }
        }
      }
    
    
    
    .accordion-button{
      &:after {
        font-weight: bold !important;
        font-size: 17px;
        border: 2px solid #FFF;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-left: 15px;
      }
    }
    
    
    .accordion-button[aria-expanded="false"] {
      &:after {
        content: "+" !important;
      }
    }
    
    .accordion-button[aria-expanded="true"] {
      &:after {
        content: "-" !important;
      }
    }
    
    
    .p-list-item, #faq-div p {
      //list-style: disc outside none;
        //display: list-item;
        margin-left: 2.5rem !important;
        
    
    }
    
    .faq-date-list {
          list-style: circle inside;
    }
    
    }
    
    .note-span {
      font-weight: bold;
      font-style: italic;
      font-size: 0.7em;
      display: none;
    }