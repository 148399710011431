.header {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  .logoutbar {
    width: 100%;
    background: transparent;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: -34px;
    position: relative;
    z-index: 5;

    a {
      margin-left: 1rem;
      color: $brand-white;
      font-size: 1rem;
      
      &:hover {
        color: $brand-white-lighter;
      }

      i {
        color: $brand-white;

        &:hover {
          color: $brand-white-lighter;
        }
      }
    }
  }

  app-banner {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;

    .mat-toolbar {
      @media (max-width: 768px) {
        //display: inline;
      }

      @media (min-width: 768px) {
        .example-spacer {
          flex: 1 1 auto;
        }

        .navitem {
          padding: 1rem;
        }
      }

      .navitem {
        cursor: pointer;
      }
    }

    .bannerBackground {
      background: #826488;
      width: 100%;
      height: auto;

      .banner-img {
        width: 100%; //change to 100% when final banner is ready
        display: flex;
        align-content: center;
        justify-content: flex-start;
        padding: 0;
        // min-width: 150px;

        img {
          width: 100%;
        }
      }
    }
  }

  .navbar {

    .nav-link{
      padding: 0.5rem 1rem;
    }

    .navbar-toggler {
      border: 1px solid #fff;
      transition: background .5s linear;

      &:hover {
        background: rgba(255, 255, 255, .1);
        transition: background .5 linear;
      }
    }

    .navbar-collapse {
      .nav-item {
        &:nth-child(2) {
          //margin-right: auto;

          @media (max-width: 991px) {
            //margin-right: 0;
          }
        }

        .is-register{
          color: $brand-primary;
        }

        .dropdown {

          // a {}

          .dropdown-menu {
            right: 0;
            left: auto;
            z-index: 1003 !important;
            color: $brand-primary;

            @media (max-width: 991px) {
              background: #212121 !important;
              color: #fff;
              border-color: transparent;
              text-align: center;
            }

            a {
              position: relative !important;
              z-index: 1005 !important;
              color: $brand-primary;


              &:hover {
                @media (max-width: 991px) {
                  opacity: .8;
                }
              }
            }
          }
        }

        &.dropdownElement {
          position: relative;
          z-index: 1000;

          @media (max-width: 991px) {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }

          .dropdown {
            z-index: 1001;

            @media (max-width: 991px) {}

            a {
              z-index: 1002;
              background: transparent;
              color: $brand-primary;

              @media (max-width: 991px) {
                width: 100%;
                color: $brand-white;
              }

              &.dropdown-toggle {
                color: $brand-white;
              }
            }
          }
        }
      }
    }
  }

  .mat-stepper-horizontal {
    &.mat-stepper-label-position-bottom {

      .mat-step-header {

        .mat-step-icon {
          width: 2rem;
          height: 2rem;

          @media (max-width: 640px) {
            width: 1rem !important;
            height: 1rem !important;
          }

          .mat-step-icon-content {
            span {
              @media (max-width: 640px) {
                display: none;
              }
            }
          }

          &.mat-step-icon-selected {
            //Custom Current State Progress cutom color otherwise will be brand primary color
            background: $brand-primary;
            width: 4rem;
            border-radius: 20px;
            font-weight: bold;
            font-size: 1.4rem;
            border: 1px solid $brand-secondary;

            @media (max-width: 640px) {
              width: 4rem !important;
              height: 2rem !important;
            }

            .mat-step-icon-content {
              span {
                display: block;
              }
            }
          }
        }
      }

      .mat-horizontal-stepper-header {
        @media (max-width: 640px) {
          padding: 6px 3px;
        }

        &:before {
          @media (max-width: 640px) {
            display: none;
          }
        }

        &:after {
          @media (max-width: 640px) {
            display: none;
          }
        }
      }

      .mat-stepper-horizontal-line {
        @media (max-width: 640px) {
          display: none;
        }
      }

      .mat-horizontal-stepper-header-container {
        @media (max-width: 640px) {
          align-items: center;
        }
      }
    }
  }

  .mat-stepper-label-position-bottom {
    .mat-horizontal-stepper-header-container {

      flex-wrap: wrap;
      justify-content: space-evenly;

      .mat-horizontal-stepper-header {
        .mat-step-label {
          padding-top: 0px;
          margin-top: 0px;
          display: none;

          &.mat-step-label-selected {
            //background: $brand-primary;
            border-radius: 5px;
            color: $brand-primary;
            padding: 5px 20px;
            display: block;

            @media (max-width: 640px) {
              padding: 5px 5px;
            }
          }
        }
      }
    }
  }
}


body {
  &.hideProgress {
    .mat-stepper-horizontal {
      display: none!important;
    }
  }
}

.text-white {
  color: #fff;
}

.font-48 {
  font-size: 48px;

  @media (max-width: 1400px) {
    font-size: 36px;
  }
  
  @media (max-width: 1050px) {
    font-size: 24px;
  }
}

.banner-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between !important;

  @media (max-width: 650px) {
    justify-content: center !important;
    text-align: center !important;
  }
}

.left-text-padding {
  padding-left: 1.5rem;

  @media (max-width: 650px) {
    padding-left: 0;
  }
}

.right-text-padding {
  padding-right: 1.5rem;
  margin-left:  0.5rem;

  @media (max-width: 650px) {
    padding-right: 0;
    margin-left: 0;
  }
}