.mat-form-field-label {
  span {
    &.mat-form-field-required-marker {
      color: #B30000 !important;
    }
  }
}

div {
  &.required {
    label {
      &:after {
        color: #B30000 !important;
        font-weight: bold!important;
      }
    }
  }
}

.mat-error, .text-danger {
  color: #B30000 !important;
  font-weight: bold!important;
}

.mat-form-field {
  &.mat-form-field-invalid {
    .mat-form-field-label {
      color: #B30000 !important;
    }
  }
}

.mat-form-field {
  &.mat-form-field-invalid {
    .mat-form-field-ripple {
      background-color: #B30000 !important;
    }
  }
}

.mat-form-field {
  &.mat-form-field-invalid {
    .mat-form-field-ripple {
      &.mat-accent {
        background-color: #B30000 !important;
      }
    }
  }
}

.mat-select, .mat-select-value, .mat-select-value-text, .mat-form-field{
  font-family: "Nobel-Book", sans-serif !important;
}